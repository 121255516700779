function dialerStateMachineReducer(state, action) {
  let newState;
  switch (action) {
    case 'CALL_NOW': newState = "calling-view"; break;
    case 'CANCEL': newState = "call-ends-view"; break;
    case 'NEW_CALL': newState = "default-view"; break;
    case 'ANSWERED' : newState = "answered-view"; break;
    case 'HANGUP': newState = "call-ends-view"; break;
    case 'REJECTED': newState = "rejected-view"; break;
    default: newState = "default-view"; break;
  }
  return newState;
};

export default dialerStateMachineReducer;