import React from 'react';
import logo from './img/logo-light.svg'

function Footer(props) {
    return (
        <footer className="footer">
            <div className="container footer-container">
                <div className="logo-footer">
                    <img src={logo} alt="logo"/>
                </div>
                <p className="copy">&copy; 2018 Coinbooth All rights reserved</p>
            </div>
        </footer>
    );
}

export default Footer;