import React from 'react';
import logo from './img/logo.svg';

function Header({ balance, topup }) {
    return (
        <header className="header" role="banner">
            <div className="container header-container">
                <div className="header-row">
                    <div className="logo">
                        <img src={logo} alt="logo" className="logo-img"/>
                    </div>
                    <div className="balance-bar">
                        <button onClick={topup} className="balance">{balance.toFixed(2)} BCH</button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;