import React, { useState, useEffect }  from 'react';

import Header from './Header';
import Dialer from './Dialer';
// import DebugPanel from './DebugPanel';
import About  from './About';
// import HowItWorks  from './HowItWorks';
// import Rates  from './Rates';
import Footer from './Footer';
import Modals from './Modals';
// import CryptoTest from './CryptoTest';

import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';

import { Provider as FigbirdProvider, useGet, useFeathers } from 'figbird';

function initiateFeathersJsClient() {
  return feathers()
    .configure(socketio(io(
      // In development mode we rely on CRA to proxy backend requests.
      // Since it cannot handle websockets, we stick to polling only.
      // This avoids a proxy error from react / webpack-dev-server
      {
          transports: process.env.NODE_ENV === "development" ? 
          ['polling'] : ['polling', 'websocket']
      }
    )))
    .configure(auth());
};

function AppProviders() {

  const [client] = useState(initiateFeathersJsClient);
  const [authenticated] = useState(false);

  return (
    <FigbirdProvider feathers={client}>
      <App authenticated={authenticated}/>
    </FigbirdProvider>
  );
}

function App({ authenticated = false }) {

  const feathers = useFeathers();
  const [userId, setUserId] = useState(null);
  const account = useGet('accounts', userId, { skip: !userId });
  const [modals, setModals] = useState({topup: false, refund: false});

  useEffect(() => {
    // Are we an exisiting user?
    if (window.localStorage["coinbooth_username"]) {
      // Do we have a JWT from a previous session that we can use?
      feathers.reAuthenticate()
        .then((data : any ) => {
          // Great, we're logged in via JWT.
          console.log("reauthenticated from prev sesh jwt");
          setUserId(data.account._id);
        })
        .catch(() => {
          // Ok, maybe it's expired. Let's try username and password...
          feathers.authenticate(
            {
              strategy: "local",
              username: window.localStorage["coinbooth_username"],
              password: window.localStorage["coinbooth_password"]
            }
          )
          .then((data : any) => {
            // Great, we're logged in via username and password.
            console.log("reauthenticated from prev sesh credentials");
            setUserId(data.account._id);
          })
          .catch(() => {
            console.log("Exisiting user but unable to login. Will create new account at next topup.");
          })}
        );
    } else {
      console.log("Not an existing user. Will wait for topup before creating account.");
    }
  });

  return (
      <>
        <Header topup={() => {setModals({...modals, topup: true})}} balance={account.data ? account.data.balance : 0}/>
        {modals.topup && <Modals account={account} modals={modals} setModals={setModals}/>}
        <main role="main">
          <Dialer/>
          {/* <DebugPanel/> */}
          <About/>
          {/* <HowItWorks/> */}
          {/* <Rates/> */}
          {/* <CryptoTest/> */}
        </main>
        <Footer/>
      </>
    );
}

export default AppProviders;
