"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var dummyLogger = {
  log: function log() {},
  error: function error() {},
  warn: function warn() {},
  debug: function debug() {}
};
exports.default = dummyLogger;